<template>
  <component
    :is="componentName(element, components)"
    v-for="element in page.elements"
    :key="element.id"
    :element="element"
    :page="element.name === 'taxon_header' ? page : null"
    :class="NARROW_ELEMENTS.includes(element.name) && 'narrow'"
  />
</template>

<script lang="ts" setup>
  import { defineAsyncComponent } from "vue"
  import { useNotificationStore } from "~/pinia/notifications"

  const props = defineProps<{
    page: AlchemyPage
  }>()

  const NARROW_ELEMENTS = [
    "text_block",
    "fragrance_teaser",
    "headline",
    "glossary",
    "documents_list",
    "testimonial",
    "faq",
  ]

  useHead(usePageInfo(props.page))
  const notificationStore = useNotificationStore()
  onMounted(() => {
    notificationStore.announce(props.page.title)
  })

  const { componentName } = useAlchemy()

  const components = {
    cards: defineAsyncComponent(() => import("~/alchemy/elements/cards.vue")),
    category_3_columns: defineAsyncComponent(
      () => import("~/alchemy/elements/category_3_columns.vue"),
    ),
    category_intro: defineAsyncComponent(
      () => import("~/alchemy/elements/category_intro.vue"),
    ),
    curalate_embed: defineAsyncComponent(
      () => import("~/alchemy/elements/curalate_embed.vue"),
    ),
    divider: defineAsyncComponent(
      () => import("~/components/Divider/index.vue"),
    ),
    email_signup: defineAsyncComponent(
      () => import("~/alchemy/elements/email_signup.vue"),
    ),
    headline: defineAsyncComponent(
      () => import("~/alchemy/elements/headline.vue"),
    ),
    helpbox: defineAsyncComponent(
      () => import("~/alchemy/elements/helpbox.vue"),
    ),
    image_full_width: defineAsyncComponent(
      () => import("~/alchemy/elements/image_full_width.vue"),
    ),
    page_header_grid: defineAsyncComponent(
      () => import("~/alchemy/elements/page_header_grid.vue"),
    ),
    panel_row: defineAsyncComponent(
      () => import("~/alchemy/elements/panel_row.vue"),
    ),
    product_list: defineAsyncComponent(
      () => import("~/alchemy/elements/product_list.vue"),
    ),
    product_slider: defineAsyncComponent(
      () => import("~/alchemy/elements/product_slider.vue"),
    ),
    quick_order_link: defineAsyncComponent(
      () => import("~/alchemy/elements/quick_order_link.vue"),
    ),
    section_with_background: defineAsyncComponent(
      () => import("~/alchemy/elements/section_with_background.vue"),
    ),
    taxon_header: defineAsyncComponent(
      () => import("~/alchemy/elements/taxon_header.vue"),
    ),
    taxon_list: defineAsyncComponent(
      () => import("~/alchemy/elements/taxon_list.vue"),
    ),
    taxon_slider: defineAsyncComponent(
      () => import("~/alchemy/elements/taxon_slider.vue"),
    ),
    testimonials: defineAsyncComponent(
      () => import("~/alchemy/elements/testimonials/index.vue"),
    ),
    text_block: defineAsyncComponent(
      () => import("~/alchemy/elements/text_block.vue"),
    ),
    grid_columns: defineAsyncComponent(
      () => import("~/alchemy/elements/grid_columns.vue"),
    ),
    wax_intro_element: defineAsyncComponent(
      () => import("~/alchemy/elements/wax_intro_element.vue"),
    ),
    wistia_embed: defineAsyncComponent(
      () => import("~/alchemy/elements/wistia_embed.vue"),
    ),
    faq: defineAsyncComponent(() => import("~/alchemy/elements/faq.vue")),
    kit_items: defineAsyncComponent(
      () => import("~/alchemy/elements/alchemy_kit_items.vue"),
    ),
  }
</script>

<style lang="scss" scoped>
  .narrow {
    max-width: $content-width-small;
    margin: 0 auto;
  }
</style>
